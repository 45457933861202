<template>
    <el-drawer title="推送通告" :visible.sync="addVisible" :before-close="closeDialog" size="auto" append-to-body>
        <div class="drawer-content">
			<el-form
			    :model="formData"
			    :rules="rules"
			    ref="formData"
			    label-position="top"
			>
			   
			   <el-form-item prop="title" label="推送名称:">
			       <el-input v-model="formData.title"  size="medium" maxlength="40"></el-input>
				   <small class="from-tip"><span class="el-icon-warning"></span>名称会展示给用户，请合理填写</small>
			   </el-form-item>
			   <el-form-item prop="content" label="消息内容:">
			       <el-input v-model="formData.content" type="textarea" :rows="6" maxlength="500" show-word-limit></el-input>
			   </el-form-item>			   
			    <el-form-item prop="type" label="关联业务:">			      
					<el-radio-group v-model="formData.type" size="medium" @change="scopeChange">
					    <el-radio :label="0">纯文字</el-radio>
					    <el-radio :label="1">演出</el-radio>
					    <el-radio :label="2">巡演</el-radio>
						<el-radio :label="3">视频</el-radio>
						<el-radio :label="4">直播事件</el-radio>
						<el-radio :label="5">直播主题</el-radio>
					</el-radio-group>
			    </el-form-item>
				<el-form-item v-if="formData.type == 0">
					<small class="from-tip"><span class="el-icon-warning"></span>会推送给您所有的粉丝</small>
				</el-form-item>
				<!-- 演出  -->
				<el-form-item prop="activityId" label="演出ID:"  v-if="formData.type == 1" :rules="[{required: true, message: '请填写演出ID'}]">
				    <el-input v-model="formData.activityId"  size="medium" maxlength="20" class="activityIpt" placeholder="请填写您发布或参与的演出"></el-input>
					<el-button type="primary" size="small" @click="analyse(1)">解析</el-button>
					<small class="from-tip"><span class="el-icon-warning"></span>会推送给您所有的粉丝</small>
					<div class="analysis" v-if="activitySite && activityTitle">
						<p v-if="activitySite">演出场地：{{activitySite}}</p>
						<p v-if="activityTitle">演出名称：{{activityTitle}}</p>
					</div>
				</el-form-item>
				<!-- 巡演  -->
				<el-form-item prop="activityId" label="巡演ID:"  v-if="formData.type == 2" :rules="[{required: true, message: '请填写巡演ID'}]" >
				    <el-input v-model="formData.activityId"  size="medium" maxlength="20" class="activityIpt" placeholder="请填写您发布或参与的巡演"></el-input>
					<el-button type="primary" size="small" @click="analyse(2)">解析</el-button>
					<small class="from-tip"><span class="el-icon-warning"></span>会推送给您所有的粉丝</small>
					<div class="analysis" v-if="xunyanSite && xunyanTitle ">
						<p v-if="xunyanSite">巡演站次：{{xunyanSite}}</p>
						<p v-if="xunyanTitle">巡演名称：{{xunyanTitle}}</p>
					</div>
				</el-form-item>
				<!-- 视频  -->
				<el-form-item prop="activityId" label="视频名称:"  v-if="formData.type == 3" :rules="[{required: true, message: '请填写视频名称'}]">				   
					<el-select
					    v-model="formData.activityId" 
					    filterable 
					    remote
					    placeholder="仅允许填写您发布的视频"
					    :remote-method="searchVideo"
					    :loading="btnLoading"
					    size="medium"
						class="activityIpt">
					    <el-option
					        v-for="item in VideoList"
					        :key="item.id"
					        :label="item.name"
					        :value="item.id">
					    </el-option>
					</el-select>
					
					<small class="from-tip"><span class="el-icon-warning"></span>会推送给您所有的粉丝</small>					
				</el-form-item>
				<!-- 直播事件  -->
				<el-form-item prop="activityId" label="直播事件:"  v-if="formData.type == 4" :rules="[{required: true, message: '请填写直播事件名称'}]">
				    <!--<el-input v-model="formData.activityId"  size="medium" maxlength="20" class="activityIpt" placeholder="仅允许填写您自己的直播事件"></el-input>-->
					<el-select
					    v-model="formData.activityId" 
					    filterable 
					    remote
					    placeholder="仅允许填写您自己的直播事件"
					    :remote-method="searchEvent"
					    :loading="btnLoading"
					    size="medium"
						class="activityIpt">
					    <el-option
					        v-for="item in liveeventList"
					        :key="item.id"
					        :label="item.title"
					        :value="item.id">
					    </el-option>
					</el-select>
					<small class="from-tip"><span class="el-icon-warning"></span>会推送给您所有的粉丝</small>					
				</el-form-item>
				<!-- 直播主题  -->
				<el-form-item prop="activityId" label="直播主题:"  v-if="formData.type == 5"  :rules="[{required: true, message: '请填写直播主题名称'}]">				    
					<el-select
					    v-model="formData.activityId" 
					    filterable 
					    remote
					    placeholder="仅允许填写您自己直播所属的主题"
					    :remote-method="searchTheme"
					    :loading="btnLoading"
					    size="medium"
						class="activityIpt">
					    <el-option
					        v-for="item in livethemeList"
					        :key="item.id"
					        :label="item.title"
					        :value="item.id">
					    </el-option>
					</el-select>
					<small class="from-tip"><span class="el-icon-warning"></span>会推送给您所有的粉丝</small>					
				</el-form-item>
			</el-form>            
        </div>
        <div class="drawer-footer">
			<el-button @click="closeDialog">取消</el-button>
            <el-button type="primary"  @click="clickBtn()" :loading="btnLoading">确 定</el-button>
        </div>
    </el-drawer>
</template>

<script>
const defaultFormData = {
    activityId:'',
    content:'',
    title:'',
    type:0
}
export default {
	props: {
	    addVisible: {
	        type: Boolean,
	        default: false,
			
	    }
	},
    data(){
        return{
			analyseLoading:false,
            btnLoading: false,
            formData:{...defaultFormData},
			activitySite:'',//演出场地
			activityTitle:'',//演出标题
			xunyanAnalyse:'',
			xunyanSite:'',//
			xunyanTitle:'',
			VideoList:[],
			liveeventList:[],
			livethemeList:[],
			rules:{			    
			    title:[{required:true,message:'请输入推送名称'},],
			    content:[{required:true,message:'请输入推送内容'}],
				//activityId:[{required:true,message:'请输入内容'}]
			},
        }
    },
    watch: {
        
    },
    mounted(){
        
    },
    methods:{
       
       closeDialog(){
           this.$emit('update:addVisible', false); 
		   this.formData = {...defaultFormData};		  
		   this.$refs.formData.resetFields();
		   this.activitySite = '';
		   this.activityTitle = '';
		   this.xunyanSite = '' ;
		   this.xunyanTitle = '';		   
       },
	   analyse(type){
		 this.analyseLoading = true;
		 this.$request({
		     url: '/fansNotice/analyse',
		     method: "POST",
		     params: {
				 activityId:this.formData.activityId,
				 type:type
			 }
		 }).then(res=>{
		     const {state, msg, result} = res.data;
		     if (state == 1) {
		         if( type == 1 ){
					 this.activitySite = result.activitySite ;
					 this.activityTitle = result.activityTitle ;					
				 }
				 if( type == 2 ){
				 	this.xunyanSite = result.tourNum ;
				 	this.xunyanTitle = result.tourName ;
				 }
		     } else {
		         this.$message.error(msg || "请求失败");
		     }
		 }).catch(() => {
		     this.$message.error("请求错误");
		 }).finally(()=>{
		     this.analyseLoading = false;
		 })  
	   },
	   //范围切换
	   scopeChange(e) {
	       if (e != 1) {
	           this.formData.activityId = "";
	       }
	   },
	   // 搜索视频
	   searchVideo(query){
		   if(query !== ""){
			   this.btnLoading = true;		  
			   this.$request({
				   url: '/video/searchByKeyWord',
				   method: "POST",
				   params: {
							 keyword:query	       				 
						 }
			   }).then(res=>{
				   const {state, msg, result} = res.data;
				   if (state == 1) {
					   this.VideoList = result
					   console.log(this.VideoList)
				   } else {
					   this.$message.error(msg || "请求失败");
				   }
			   }).catch(() => {
				   this.$message.error("请求错误");
			   }).finally(()=>{
				   this.btnLoading = false;
			   })  
			}
	   },
	   // 搜索事件
	   searchEvent(query){
		   if(query !== ""){
			   this.btnLoading = true;		  
			   this.$request({
				   url: '/liveEvent/searchByKeyWord',
				   method: "POST",
				   params: {
							 keyword:query	       				 
						 }
			   }).then(res=>{
				   const {state, msg, result} = res.data;
				   if (state == 1) {
					   this.liveeventList = result	   					  
				   } else {
					   this.$message.error(msg || "请求失败");
				   }
			   }).catch(() => {
				   this.$message.error("请求错误");
			   }).finally(()=>{
				   this.btnLoading = false;
			   })  
			}
	   },
	   // 搜索主题
	   searchTheme(query){
		   if(query !== ""){
			   this.btnLoading = true;		  
			   this.$request({
				   url: '/liveTheme/searchByKeyWord',
				   method: "POST",
				   params: {
							 keyword:query	       				 
						 }
			   }).then(res=>{
				   const {state, msg, result} = res.data;
				   if (state == 1) {
					   this.livethemeList = result	   					  
				   } else {
					   this.$message.error(msg || "请求失败");
				   }
			   }).catch(() => {
				   this.$message.error("请求错误");
			   }).finally(()=>{
				   this.btnLoading = false;
			   })  
			}
	   },
	   clickBtn(){
		   
		   if( this.activitySite == '' && this.formData.type == 1 || (this.xunyanSite == '' && this.formData.type == 2 ) ){
				return this.$message.error("请先解析！");
				
		   }else if( this.formData.type == 1 && this.activitySite != ''){
			   
			   this.$request({
			     url: "/fansNotice/checkActivityMessage",                       
			   	 params: {activityId:this.formData.activityId},
			     method: "post",
			   })
			   .then((res) => {
				   const { state, msg , result } = res.data;
				   if (state == 1 && result == 0) {
					  console.log(this.formData.activityId)
					  this.submitForm();
				   }else if( state == 1 && result == 1 ){
						const _html = "<div>\
						    <div style='font-weight:700'>该演出在24h内已经被推送过</div>\
						    <div>鉴于短时间内推送相同内容会<span class='color-danger'>导致用户关闭推送</span>，使推送内容无法触达粉丝，本次推送将<span class='color-danger'>不会对已收到相同推送的用户下发</span></div>\
							</div>"
						this.$confirm(_html, '', {
						    dangerouslyUseHTMLString: true,
							confirmButtonText: '确定推送',
							cancelButtonText: '取消',
						}).then(() => {
						    this.submitForm();
						    this.btnLoading = false;
						}).catch(() => {
							
						    this.btnLoading = false;
						}); 
							
				   }else {
					   this.$message.error(msg || "操作失败,请重试");
				   }
			   })
			  
		   }else{		   
			   this.submitForm();
		   }
	   },
	   submitForm() {
	       let self = this;	
		   if( this.formData.type == 0  ){
			   this.formData.activityId = 0
		   }
			this.btnLoading = true
	       self.$refs["formData"].validate((valid) => {
	           if (valid) {
				   
	               this.btnLoading = true;	   			
	               this.$request({
	                 url: "/fansNotice/send",                       
	   				 data: {...this.formData},
	                 method: "post",
	               })
	                   .then((res) => {
	                       const { state, msg } = res.data;
	                       if (state == 1) {
	                           this.$emit('update');
	                           self.closeDialog();
	                       } else {
	                           self.$message.error(msg || "操作失败,请重试");
	                       }
	                   })
	                   .catch(() => {
	                       self.$message.error("操作失败,请重试");
	                   })
	                   .finally(() => {
	                       this.btnLoading = false;
	                   });
	           } else {
	               return false;
	           }
	       });
	   },
    }
}
</script>
<style lang="scss" scoped>
.activityIpt{
	margin-right: 20px;
	width: 70%;
}
.el-drawer .el-form small, .page-main .el-form small{
	display: block;
}
.analysis{
	padding-top: 15px;
	color: #145AF2;
	p{
		margin-bottom: -15px;
	}
}
.info-content{
    border-bottom: 1px solid #dcdfe6;
    &>li{
        display: flex;
        border: 1px solid #dcdfe6;
        &:not(.last){
            border-bottom: none;
        }
        span{
            padding: 16px; 
        }
        .div{
            padding: 16px 0;
        }
        .title{                  
            width: 120px;
            box-sizing: border-box;
            background: #f5f7fa;
            border-right: 1px solid #dcdfe6;
            flex-shrink: 0;
        }
    }
    
}
</style>