<template>
    <el-drawer title="推送详情" :visible.sync="detailVisible" :before-close="closeDialog" >
        <div class="drawer-content">
			<ul class="info-content">
			    <li><span class="title">推送名称</span>
			        <span>{{ detailForm.title }}</span>
			    </li>
			    <li>
			        <span class="title">消息内容</span>
			        <span>{{ detailForm.content }}</span>
			    </li>
			    <li>
			        <span class="title">关联业务</span>
			        <span>{{ detailForm.target }}</span>
			    </li>
			    <li>
			        <span class="title">业务对象</span>
			        <span>
						<template>
						    <p v-if="detailForm.type == 0">纯文字</p>
						    <p v-if="detailForm.type == 1">演出</p>
						    <p v-if="detailForm.type == 2">巡演</p>
							<p v-if="detailForm.type == 3">视频</p>							
							<p v-if="detailForm.type == 4">直播事件</p>
							<p v-if="detailForm.type == 5">直播主题</p>
						</template>						
					</span>
			    </li>
				<li>
				    <span class="title">推送时间</span>
				    <span>{{ detailForm.createTime}}</span>
				</li>
				<li>
				    <span class="title">状态</span>
				    <span>						
						<div v-if="detailForm.status == 0" class="color-warning">待审核</div>
						<div v-if="detailForm.status == 1" class="color-success">已通过</div>
						<div v-if="detailForm.status == 2" >已拒绝</div>
						<div v-if="detailForm.rejectionReason" class="color-danger">{{detailForm.rejectionReason}}</div>
					</span>
				</li>
			</ul>            
        </div>
        <div class="drawer-footer">           
            <el-button type="primary" @click="closeDialog">确 定</el-button>
        </div>
    </el-drawer>
</template>

<script>
export default {
	props: {
	    detailVisible: {
	        type: Boolean,
	        default: false
	    },
	    detailId: {
	        type: [Number, String],
	        default: null
	    }
	},
    data(){
        return{           
            btnLoading: false,
            detailForm: {}     
        }
    },
    watch: {
        detailId(n){
            this.getInfo(n);// 0纯文字 1=演出 2=巡演 3视频 4直播事件 5直播主题
        }
    },
    mounted(){
        
    },
    methods:{
       getInfo(id){
           this.btnLoading = true;
           this.$request({
               url: '/fansNotice/detail',
               method: "POST",
               params: {id}
           }).then(res=>{
               const {state, msg, result} = res.data;
               if (state == 1) {
                   this.detailForm = result
               } else {
                   this.$message.error(msg || "请求失败");
               }
           }).catch(() => {
               this.$message.error("请求错误");
           }).finally(()=>{
               this.pageLoading = false;
           })
       },
       closeDialog(){
           this.$emit('update:detailVisible', false);      
       },
       
       
       
       
    }
}
</script>


<style lang="scss" scoped>
.info-content{
    border-bottom: 1px solid #dcdfe6;
    &>li{
        display: flex;
        border: 1px solid #dcdfe6;
        &:not(.last){
            border-bottom: none;
        }
        span{
            padding: 16px; 
        }
        .div{
            padding: 16px 0;
        }
        .title{                  
            width: 120px;
            box-sizing: border-box;
            background: #f5f7fa;
            border-right: 1px solid #dcdfe6;
            flex-shrink: 0;
        }
    }
    
}
</style>