<template>
    <div>
        <div class="page-content-box">
			<el-alert show-icon type="warning" v-if="data" :closable="false" >
                <div slot="title">每月只能发送{{data.monSendCount}}次通告,您本月还能发送{{data.monSurSendCount}}次。每月1日重置次数</div>
            </el-alert>
            <div class="page-list-box">
                <el-table
                    :data="dataList"
                    style="width: 100%"
                    v-loading="listLoading"
                    size="small"
                    header-row-class-name="page-list-table"
                >
                    <el-table-column prop="status" label="状态">
						<template slot-scope="scope">
						    <span v-if="scope.row.status == 0" class="color-warning">待审核</span>
							<span v-if="scope.row.status == 1" class="color-success">已通过</span>
							<span v-if="scope.row.status == 2" >已拒绝</span>
						</template>
					</el-table-column> 
					<el-table-column prop="title" label="推送名称" min-width="250"></el-table-column>                    
                    <el-table-column prop="createTime" label="推送时间" min-width="150"></el-table-column>
                    <el-table-column fixed="right" label="操作" width="90">
                        <template slot-scope="scope">
                            <el-button
                                type="text"
                                size="small"
                               @click="detailVisible = true; detailId=scope.row.id;"
                                >查看详情</el-button
                            >                            
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div  class="page-pagination-box clearfix MT20">
                <div class="fl" v-if="data"><el-button type="primary" size="small"  @click="addVisible = true" v-if="data.isSend == 1 ">推送通告</el-button></div>
                <el-pagination
                    v-if="data"
                    class="fr"
                    @size-change="handleSizeChange"
                    @current-change="getDataList"
                    :current-page.sync="params.pageNo"
                    :page-sizes="[15, 30, 50]"
                    :page-size.sync="params.pageSize"
                    layout="sizes, prev, pager, next"
                    :total="data.page.totalCount || 0">
                </el-pagination>
            </div>
        </div>
        <detail :detailVisible.sync="detailVisible" :detailId="detailId"/>
		<add :addVisible.sync="addVisible"  @update="getDataList" />
    </div>
</template>

<script>
import Detail from './components/detail';
import Add from './components/add';
export default {
    components:{Detail,Add},
    data(){
        return{
            data: null,
            dataList: null,
            params:{
                pageNo: 1,
                pageSize: 15,				
            },
            listLoading: true,			
			detailVisible: false,			
			detailId: null,
			addVisible:false,
        }
    },
    mounted(){
        this.getDataList();
    },
    methods:{
        //获取列表
        getDataList() {
            this.listLoading = true;
            this.$request({
                url: "/fansNotice/list",
                method: "POST",
                params: this.params,
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.data = result;
                        this.dataList = result.page.result;
						
						
                    } else {
                        this.$message.error(msg || "请求失败");
                    }
                })
                .catch(() => {
                    this.$message.error("请求错误");
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        //选择pageSize
        handleSizeChange(e) {
            this.params.pageNo = 1;
            this.params.pageSize = e;
            this.getDataList();
        },
	   
        
    }
}
</script>